import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import "../assets/css/entrega.css";
import history from '../history';
import swal from 'sweetalert';
import { useStateValue } from 'contexts';
import api from  '../api.js';
import Mapa from '../components/map';




export default function Lista(props) {
  
  const [{pedido}] = useStateValue();

  // const main = MainApp();
  // const {setUser} = MainApp();
//   console.log(pedido);

  useEffect(() =>{
    if(!pedido)  history.push("/lista");
  },[pedido]);
  
  if(!pedido) return null;
  

    
 
  const localizacaoCliente = pedido.latlng.split(',');
  const urlClienteWaze = "https://www.waze.com/ul?ll="+localizacaoCliente[0]+"%2C"+localizacaoCliente[1]+"&navigate=yes&zoom=17";
  
  async function finalizarPedido(event){
    
    swal("Finalizar entrega?", {
      buttons: {
        cancel: {
          text: "Cancelar",
          value: "catch",
          visible: true,
          className: "",
          closeModal: true,
          defeat: true,
        },
        confirm: {
          text: "Finalizar",
          value: "aceitou",
          visible: true,
          className: "",
          closeModal: true,
          defeat: true,
        }
      },
    })
    .then((value) => {
      switch (value) {
        case "catch":
            break;
        case "aceitou":
            var postData = {
              pedido:pedido.id,
            };
            api.post('/pedidos/fecha',postData, {
              headers: {'authorization': 'Bearer '+sessionStorage.getItem('token')}}
            )
            .then(function (response,data) {
              if(response.data.res) swal("Bom!", "Entrega Finalizada!", "success"); history.push("/lista");
              
            })
            .catch(function (error) {
                console.log(error);
            //   swal("Oops!", "Login ou senha Inválidos !");
            });
          // swal("Bom!", "Voce aceitou esta entrega!", "success");
          // history.push("/entrega");
        break;
        default:
          swal("Got away safely!");
      }
    });
}

//   setTimeout(function() {
//     setCardAnimation("");
//   }, 700);
  
  function escapeHtml(text) {
    return text.replace( "%20", " ");
  }
  
  return (
    <div>
        <div className='mapa'>
           <Mapa/>
        </div>
        <div className='customPanel'>
          <div className='endereço'>
            {sessionStorage.getItem('empresa')}
            <a target='_blank' href={urlClienteWaze}>
              <img src='https://loca.app.br/img/icon-waze-download.png' className='iconWaze'/>
            </a>
            <hr/>
            <b>Cliente:</b> {escapeHtml(pedido.nome)} - Pedido <b>{pedido.codigo_pedido}</b><br/>
            <b>Rua:</b> {pedido.rua}, <b>Nº</b> {pedido.unidade} - 
            <br/><b>Bairro: </b> {pedido.bairro} 
            <b> Cidade:</b> {pedido.cidade} - {pedido.uf}
            <br/> <b>Complemento:</b> {pedido.complemento}<br/>
            <b>Observação:</b> {pedido.observacao}<br/>
            <b>Pagamento:</b> {pedido.forma_pagamento}<br/>
            <b>Total:</b> R$ {pedido.total}<br/>

            <hr/>
          </div>
          <div className='ftb'>
              <Button variant="outlined" size="medium" color="primary"  onClick={() => {  history.push("/lista") }} className='buttonftb'>
                Voltar
              </Button>
              <Button variant="outlined" size="medium" color="primary" className='buttonftb' data-id={pedido.id} onClick={finalizarPedido} >
                Finalizar pedido
              </Button>
          </div>
        </div>
    </div>
  );
}
// https://www.cluemediator.com/add-multiple-custom-markers-to-google-maps-in-reactjs
// https://www.cluemediator.com/add-multiple-custom-markers-to-google-maps-in-reactjs