import React from "react";
// @material-ui/core components
import TextField from '@material-ui/core/TextField';
// @material-ui/icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import Fingerprint from '@material-ui/icons/Fingerprint';

// core components
import Button from '@material-ui/core/Button';

import swal from 'sweetalert';
import api from  '../api.js';
import history from '../history';
import "../assets/css/login.css";
import image from "../assets/img/mapa.jpg";

export default function LoginPage(props) {

  const [login, setLogin] = React.useState("");
  const [pass, setPass] = React.useState("");

  async function handleSubmit(){
     
      const dataObj = {login,pass};
      api.post('/entregador/login',dataObj
      )
      .then(function (response,data) {
        data = response.data;
        if(!data.token && data.user[0].id)
           console.log(data);
           sessionStorage.setItem('token', data.token);
           sessionStorage.setItem('empresa', data.user[0].nome_empresa);
           console.log("redirecionar agora");
           history.push("/lista");
      })
      .catch(function (error) {
        swal("Oops!", "Login ou senha Inválidos !");
      });
  }
  const handleChange = (event) => {
    if (event.target.name === 'login') setLogin(event.target.value);
    if (event.target.name === 'pass') setPass(event.target.value);
  };
  return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            width: '100%',
            position: 'absolute',
            height: '100%'
          }}
        >
      <div>
        <form   className="cardlogin">
          <div className="contentLogin" >
            <br/>
            <div className="w100">
                <div>
                  <AccountCircle className="iconeLogin"/>
                </div>
                <div className="w100">
                  <TextField fullWidth name="login" placeholder="Login" value={login}  onChange={handleChange}/>
                </div> 
            </div>
            <div className="w100">
                <div>
                  <VpnKey className="iconeLogin"/>
                </div>
                <div className="w100">
                  <TextField fullWidth name="pass" type="password" placeholder="*****"  value={pass} onChange={handleChange} />
                </div>
            </div>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="btnLogin"
              endIcon={<Fingerprint style={{color: "#FFFFFF"}}/>}
              onClick={handleSubmit}
            >
              Entrar
            </Button>
          </div>
        </form>
        </div>     
      </div>
    </div>
  );
}