import firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyD4bkdJnK6grGa7eZ0qpQ-J-Vma1j93RHk",
    authDomain: "locaship-d1d7f.firebaseapp.com",
    projectId: "locaship-d1d7f",
    storageBucket: "locaship-d1d7f.appspot.com",
    messagingSenderId: "444133726197",
    appId: "1:444133726197:web:4a54c12b1549f85340f2bc",
    measurementId: "G-HNYMSMZZ10",
    databaseURL: "https://locaship-d1d7f-default-rtdb.firebaseio.com/",
    storageBucket: "locaship-d1d7f-default-rtdb:entregadores"
  };

firebase.initializeApp(firebaseConfig);