import React from 'react';
import { Redirect } from 'react-router-dom';

import { StateProvider } from '../src/contexts/index';
import reducers from '../src/contexts/reducer';
import initialState from '../src/contexts/initialState';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const isAuthenticated = sessionStorage.getItem('token');
        return isAuthenticated ? (
            <StateProvider initialState={initialState} reducer={reducers}>
                 <Component />
            </StateProvider>
        ) : (
            <Redirect to={{ pathname: '/' }} />
        );
    }
}

export default ProtectedRoute;