import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import history from "../src/history";
import ProtectedRoute from '../src/ProtectedRoute';
//paginas 
import LoginPage from '../src/pages/login';
import ListaPage from '../src/pages/lista';
import EntregaPage from '../src/pages/entrega';
import "../src/assets/css/main.css";
require('./firebase');

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <ProtectedRoute exact={true} path="/entrega" component={EntregaPage} />
      <ProtectedRoute exact={true} path="/lista" component={ListaPage} />
      <Route path="/" component={LoginPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
