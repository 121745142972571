import React from "react";
import Button from '@material-ui/core/Button';

import "../assets/css/lista.css";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import history from '../history';

import Pedidos from '../components/pedidos';


export default function lista(props) {
  
  return (
    <div>
        <div>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton edge="start"  color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Button color="inherit" onClick={() => {  history.push("/") }} className='btnSair'>Sair</Button>
            </Toolbar>
          </AppBar>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <Pedidos/>
    </div>
  );
}
