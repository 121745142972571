import React,{useEffect } from "react";
import api from  '../api.js';
import Button from '@material-ui/core/Button';
import NavigationIcon from '@material-ui/icons/Navigation';
// import {MainApp}  from "../providers/main";
import history from '../history';
import swal from 'sweetalert';
import { useStateValue } from 'contexts';


export default function Pedidos(){
    const [lista, setLista] = React.useState();
    const [{}, dispatch] = useStateValue();
 
    var axiosConfig = {
      headers: {
          'authorization': 'Bearer '+sessionStorage.getItem('token')
      }
    };

    function verPedido(event) {
      var idp = event.currentTarget.dataset.id;
      lista.forEach(element => {
        if(element.id == idp )
          dispatch({
            type: 'setPedido',
            payload: element
          });
      });
      history.push("/entrega");
    }
    async function accPedido(event){
        var idp = event.currentTarget.dataset.id;
        swal("Aceitar entrega?", {
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "catch",
              visible: true,
              className: "",
              closeModal: true,
              defeat: true,
            },
            confirm: {
              text: "Aceitar",
              value: "aceitou",
              visible: true,
              className: "",
              closeModal: true,
              defeat: true,
            }
          },
        })
        .then((value) => {
          switch (value) {
            case "catch":
              break;
            case "aceitou":
              // swal("Bom!", "Voce aceitou esta entrega!", "success");
              // history.push("/entrega");
              pegaPedido(idp);
              break;
            default:
              swal("Got away safely!");
          }
        });
    }

    function pegaPedido(idp) {
      var postData = {
        pedido:idp,
        token:sessionStorage.getItem('token')
      };
      api.post('/pedidos/acc',postData, axiosConfig
      )
      .then(function (response,data) {
        if(response.data.res) pedidoAbertos();
        
      })
      .catch(function (error) {
          console.log(error);
      //   swal("Oops!", "Login ou senha Inválidos !");
      });
    }

    function pedidoAbertos(){
        api.get('/pedidos', axiosConfig
        )
        .then(function (response,data) {
          if(response)
            setLista(response.data);
        })
        .catch(function (error) {
            console.log(error);
        //   swal("Oops!", "Login ou senha Inválidos !");
        });
    }

    function escapeHtml(text) {
        return text.replace( "%20", " ");
    }

    // useEffect(() => {
         console.log(lista);
        if(lista === undefined){
            pedidoAbertos();
        }else{
           
        }
    // },lista);

    setTimeout(() => {
      
        pedidoAbertos();
    }, 15000);
    
    try{
        return(
            <div>
            {lista.map((lista, index) => (
              <div>
                 {lista.status == "Entrega" ?
                            <div className="Pedido" >
                                {sessionStorage.getItem('empresa')}
                                <hr/>
                                <b>Cliente:</b> {escapeHtml(lista.nome)} - Pedido <b>{lista.codigo_pedido}</b>
                                <br/><b> Cidade:</b> {lista.cidade} <b> - Bairro: </b> {lista.bairro} - <b>Rua:</b> {lista.rua} -  <b>Nº</b> {lista.unidade},
                                <br/> <b>Complemento:</b> {lista.complemento}<br/>
                                <b>Observação:</b> {lista.observacao}
                                <hr/>
                                <Button type="button" variant="outlined" color="primary" data-id={lista.id} onClick={verPedido} className="entregaBtn">
                                <NavigationIcon/>
                                ver entrega
                                </Button>
                            </div>
                            :
                            <div className="Pedido" >
                            {sessionStorage.getItem('empresa')}
                            <hr/>
                            <b>Cliente:</b> {escapeHtml(lista.nome)} - Pedido <b>{lista.codigo_pedido}</b>
                            <br/><b> Cidade:</b> {lista.cidade} <b> - Bairro: </b> {lista.bairro} - <b>Rua:</b> {lista.rua},
                            <hr/>
                            <Button type="button" variant="outlined" color="primary" data-id={lista.id} onClick={accPedido} className="aceitarBtn">
                            <NavigationIcon/>
                            Aceitar entrega
                            </Button>
                        </div>}
                
              </div>
            ))}
           </div>
        );
    }catch(exception){
        return(
           <div>
           
           </div>
        );
    }
   
    
}